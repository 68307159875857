import { TLocalLanguage } from '@/i18n/type';

export default function GotoText(props: {
  lng: TLocalLanguage;
}) {
  const text = {
    en: 'goto',
    es: 'ir a',
    de: 'gehe zu',
    pt: 'ir para',
    fr: 'aller à',
  };

  return (
    <>{ text[props.lng] }</>
  );
}
