'use client';
import clsx from 'clsx';
import usePagination from './usePagination';
import styles from './pagination.module.scss';
import { IPaginationProps, DotTypeEnum } from '@/types/pagination';
import { ListenBriefImg } from '@/config/img/listenbiref';
import { Image } from '@nextui-org/react';

interface IProps extends IPaginationProps {
  prevImgURL?: string;
  nextImgUrl?: string;
  textStyle?: string;
  btnStyle?: string;
  activeTextStyle?: string;
  arrowStyle?: string;
  activeStyle?: string;
  pageNumStyle?: string;
}

export default function Pagination(props: IProps) {
  const {
    range,
    setPage,
    activePage,
  } = usePagination(props);
  const {
    pageNumStyle = 'text-xl px-4 cursor-pointer',
  } = props;
  return (
    <div className={
      clsx('pagination-custom flex justify-center items-center', props.className)
    }>
      <div
        className={
          clsx('left-arrow w-10 h-10 flex items-center justify-center mr-5',
            props.btnStyle ?? styles.borderColor,
            activePage === 1 ? 'cursor-not-allowed' : 'cursor-pointer',
          )
        }
        onClick={() => {
          if (activePage <= 1) {
            return;
          }
          setPage(activePage - 1);
        }}
      >
        <Image loading='lazy' radius='none' src={props.prevImgURL ?? ListenBriefImg.LeftArrow} alt='go prev page' className={props.arrowStyle ? props.arrowStyle : 'w-5 h-5'} />
      </div>
      <div className='page-content'>
        {
          range.map((rn, index) => {
            return (
              <span
                key={`${rn}-${index}`}
                className={
                  clsx(rn === activePage && (props?.activeStyle ?? 'text-[#3AD1FF]'), pageNumStyle)
                }
                onClick={() => {
                  if (rn === DotTypeEnum.PREV_DOT) {
                    setPage(activePage - 3);
                  } else if (rn === DotTypeEnum.NEXT_DOT) {
                    setPage(activePage + 3);
                  } else {
                    setPage(rn);
                  }
                }}
              >{
                  Object.values(DotTypeEnum).includes(rn)
                    ? '...'
                    : rn
                }</span>
            );
          })
        }
      </div>
      <div
        className={
          clsx('left-arrow w-10 h-10 flex items-center justify-center ml-5',
            props.btnStyle ?? styles.borderColor,
            activePage === props.total ? 'cursor-not-allowed' : 'cursor-pointer',
          )
        }
        onClick={() => {
          if (activePage >= props.total) {
            return;
          }
          setPage(activePage + 1);
        }}
      >
        <Image loading='lazy' radius='none' src={props.nextImgUrl ?? ListenBriefImg.RightArrow} alt='go next page' className={props.arrowStyle ? props.arrowStyle : 'w-5 h-5'} />
      </div>
    </div>
  );
}

